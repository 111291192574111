import { BehaviorSubject } from "rxjs";
import { Message, Notification } from "../models/notification.type";

export class NotificationService {

    private messagePool:Message[] = [];

    private message$= new BehaviorSubject<Message | undefined>(undefined);
    public onMessage = this.message$.asObservable();

    public sendMessage(message: Message) {
        if (message) {
            this.message$.next(message);
            this.messagePool.push(message);
        }
    }
    public sendNotification(notification: Notification) {

    }
}